export default defineNuxtRouteMiddleware(async (to) => {
  const { init, user } = useAuth();
  await init();

  // both auth and nonauth
  if (['/payment-result', '/tos'].includes(to.path)) {
    return;
  }

  if (to.path.startsWith('/shop')) {
    return;
  }

  // only non auth
  if (to.path === '/login' || to.path === '/register') {
    if (user.value !== null) {
      return navigateTo('/');
    }

    return;
  }

  // the rest should be auth
  if (user.value === null) {
    return navigateTo('/login');
  }
});
